<template>
    <Layout>
        <p>
            <span class="title">Unit Number :</span>
            &nbsp;&nbsp;{{ payload.unit }}
        </p>
        <p>
            <span class="title">NOC Type :</span>
            &nbsp;&nbsp;{{ payload.noc_type }}
        </p>
        <p>
            <span class="title"> Name :</span>
            &nbsp;&nbsp;{{ payload.first_name }}&nbsp;&nbsp;{{
                payload.last_name
            }}
        </p>
        <p>
            <span class="title">Email :</span>
            &nbsp;&nbsp;{{ payload.email }}
        </p>
        <p>
            <span class="title">Contact Number :</span>
            &nbsp;&nbsp;{{ payload.contact_number }}
        </p>

        <p>
            <span class="title">Amount :</span>
            &nbsp;&nbsp;{{ payload.amount }}
        </p>
        <p>
            <span class="title">Invoice Expiry Date :</span>
            &nbsp;&nbsp;{{ payload.invoice_exp_date }}
        </p>
        <p>
            <span class="title">Order Reference Id :</span>
            &nbsp;&nbsp;{{ payload.order_reference }}
        </p>
        <!-- <p>
            <span class="title">Payment Link :</span>
            &nbsp;&nbsp;{{ payload.payment_link }}
        </p> -->
        <p>
            <span class="title">Created By :</span>
            &nbsp;&nbsp;{{ payload.created_by }}
        </p>
        <p>
            <span class="title">Created At :</span>
            &nbsp;&nbsp;{{ payload.created_at }}
        </p>
        <p>
            <span class="title">Status :</span>
            &nbsp;&nbsp;<span
                :class="payload.payment_status === 'Paid' ? 'paid' : 'pending'"
                >{{ payload.payment_status }}</span
            >
        </p>
    </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";

export default {
    components: {
        Layout,
    },
    props: {
        payload: Object,
    },
};
</script>
<style scoped>
.title {
    font-weight: bolder;
    color: black;
}
p {
    color: black;
}
.paid {
    font-weight: bolder;
    color: green;
}
.pending {
    font-weight: bolder;
    color: red;
}
</style>
